<!-- 通行证 -->

<template>
  <div class="vip-container main-cnt">
    <div class="title">通行证列表</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="ScenicApi.getPassportList" :columns="tableColumns">
        <template #editOpenDialog="{ row }">
          <el-button v-if="row.op_status == '2' && authData.indexOf('s_EtcWxmmgBZMO7x3NtyT9IoYRJ') != -1"
            @click="editOpenDialog(row)">重新注册
          </el-button>
        </template>
      </common-table>
    </div>

    <!-- 重新注册 -->
    <w-dialog ref="registeredDialog" class="enter-dialog" title="重新注册" width="40%" btnWidth="140px" top="10vh"
      confirmText="确认注册" @wConfirm="onConfirmRegistration">
      <div class="ticket-content">
        <KeyBoard ref="keyBoardRef" v-model="qr_code" :placeholder="'请用扫码枪识别手环码'"></KeyBoard>
      </div>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch, nextTick } from "vue";
  import { ScenicApi } from "@/plugins/api.js";
  import { ElMessage, ElMessageBox } from "element-plus";
  import dayjs from "dayjs";
  import { useStore } from "vuex";
  import KeyBoard from "@/components/key-board/keyboard.vue";

  const store = useStore();
  const authData = ref([]);  // 权限
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  /**
   *
   * 监听权限
   *
   **/
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  onMounted(() => {
    tableRef.value.tableLoad();
  });
  /** 表格对象 */
  const tableRef = ref(null);
  const filters = ref([
    {
      filterType: "searchKey",
      name: "keywords",
      value: "",
      placeholder: "搜索通行证、会员卡编号、手机号",
    },
    {
      filterType: "select",
      name: "type",
      value: "",
      placeholder: "请选择类型",
      options: [{ id: '1', name: '儿童票' }, { id: '2', name: '成人票' }],
      val: "id",
      lab: "name",
    },
    {
      filterType: "date",
      name: "start_time",
      name2: "end_time",
      value: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      placeholder: "请选择日期范围",
      type: "daterange",
    },
  ]);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "mdrr_sn",
      label: "编号",
      color: "--text-third-color",
      minWidth: 120,
    },
    {
      prop: "s_name",
      label: "园区",
      minWidth: 120,
    },
    {
      prop: "record_type_text",
      label: "类型",
    },
    {
      prop: "mdrr_date",
      label: "有效日期",
      minWidth: 120,
    },
    {
      prop: "record_register_text",
      label: "注册状态",
    },
    {
      prop: "mcd_sn",
      label: "会员卡编号",
      minWidth: 140,
    },
    {
      prop: "m_realname",
      label: "会员名称",
    },
    {
      prop: "m_mobile",
      label: "联系方式",
      minWidth: 120,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "重新注册",
          action: "editOpenDialog",
          type: "customRender",
          className: "theme-font-btn",
        },
      ],
    },
  ]);
  const qr_code = ref('');  // 下发编号
  const mdrr_id = ref('');  // 下发id
  const registeredDialog = ref(null);  // 重新注册弹框对象
  const keyBoardRef = ref(null);  // 软键盘对象
  /**
   * 
   * 重新注册按钮
   * 
   * **/
  const editOpenDialog = (row) => {
    mdrr_id.value = row.mdrr_id;
    registeredDialog.value.show();
    nextTick(() => {
      keyBoardRef.value.clickKey(row.mdrr_sn);
    })
  };
  /**
   * 
   * 重新注册确认按钮
   * 
   * **/
  const onConfirmRegistration = () => {
    if (!qr_code.value) {
      ElMessage.error("请用扫码枪识别手环码");
      return false;
    }
    registeredDialog.value.isLoading = true;
    ScenicApi.passportReregistration({ mdrr_id: mdrr_id.value, qr_code: qr_code.value }).then((res) => {
      registeredDialog.value.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success("操作成功！");
        tableRef.value.tableLoad();
        registeredDialog.value.close();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
</script>
<style lang="scss">
  .vip-container {
    font-family: "Source Han Sans CN";

    .ticket-content {
      min-height: 100px;
    }

    .content {
      padding: 15px 20px 20px;
    }

    .vip-dialog {
      .el-dialog {
        min-width: 600px;

        .el-dialog__body {
          padding: 30px;

          .el-divider--horizontal {
            margin: 10px 0;
          }
        }
      }
    }
  }
</style>